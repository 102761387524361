<template>
  <div>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/dataManage-dataExport/dataExport'}">数据导出</el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="(item,index) in parentLevel"
          :key="index">
          <span @click="initWithTableId(item.id,index)" class="el-breadcrumb__inner is-link" role="link">{{item.name}}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="init()">
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="具体层级数据名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="selectByNameAndInit()">查询</el-button>
          <el-button @click="resetTable()">重置</el-button>
          <!--        <el-button v-if="isAuth('generator:level:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>-->
<!--          <el-button type="primary" @click="addOrUpdateHandle(isLeaf)">新增</el-button>-->
          <!--        <el-button v-if="isAuth('generator:level:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>-->
<!--          <el-button  type="danger" @click="deleteHandle(tableId)" :disabled="dataListSelections.length <= 0">批量删除</el-button>-->
        </el-form-item>
      </el-form>

      <el-table :data="tableData" border width="80%" @selection-change="selectionChangeHandle">
        <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50">
        </el-table-column>
        <template v-for="(item,index) in tableCols">
          <el-table-column :prop="item.cols"
                           :label="item.name"
                           :key="index">
          </el-table-column>
        </template>
        <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="200"
            label="操作">
          <template slot-scope="scope">
    <!--        <el-button v-if="isLeaf===0" type="text" size="small" @click="initWithParent(tableId, scope.row.id)">查看子层级</el-button>-->
            <el-button v-if="isLeaf!==0 && scope.row.is_leaf!=='是'" type="text" size="small" @click="initWithParent(tableId, scope.row.id,scope.row.hbaseTableName,scope.$index)">查看子层级</el-button>
            <el-button type="text" size="small "@click="handleExport(scope.row.id,scope.row.hbaseTableName)" >导出</el-button>
            <el-button type="text" size="small "@click="handleDownload(scope.row.id,scope.row.hbaseTableName)" >下载</el-button>

<!--            <el-button type="text" size="small" @click="addOrUpdateHandle(isLeaf,scope.row.id)" >修改</el-button>-->
<!--            <el-button type="text" size="small" style="color: red" @click="deleteHandle(tableId,scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
        <el-table-column
            prop="process"
            label="进度"
            header-align="center"
            align="center"
            width="200">
          <template slot-scope="scope">
            <el-progress :percentage="scope.row.process"></el-progress>
            <span v-if="scope.row.msg">{{scope.row.msg}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>

      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="initWithParent"></add-or-update>
    </div >
<!--    <template>-->
<!--      <dataExport ref="exportVue" :tableHeaderTest="tableHeaderTest" :hbaseTableName="hbaseTableName"></dataExport>-->
<!--&lt;!&ndash;    <el-tabs  v-model="activeName" type="card">&ndash;&gt;-->
<!--&lt;!&ndash;      <el-tab-pane label="表格信息" name="chart">&ndash;&gt;-->
<!--&lt;!&ndash;        <chart v-if="activeName == 'chart'" ref="exportVue" :tableHeaderTest="tableHeaderTest"  :hbaseTableName="hbaseTableName"></chart>&ndash;&gt;-->
<!--&lt;!&ndash;      </el-tab-pane>&ndash;&gt;-->
<!--&lt;!&ndash;      <el-tab-pane label="图表信息" name="graph">&ndash;&gt;-->
<!--&lt;!&ndash;        <graph v-if="activeName == 'graph'"></graph>&ndash;&gt;-->
<!--&lt;!&ndash;      </el-tab-pane>&ndash;&gt;-->
<!--&lt;!&ndash;    </el-tabs>&ndash;&gt;-->

<!--    </template>-->
  </div>
</template>

<script>
import AddOrUpdate from '/src/views/modules/levelManage/universal-table-add-or-update.vue'
import dataExport from './data-export-subEquipment'
// import chart from "./dataDetail_chart";
// import graph from "./dataDetail_graph.vue";
export default {
  name: "universal-table",
  data () {
    return {
      hbaseTableNameList: [],
      hbaseTableName:'',
      tableHeaderTest: [],
      index: 0,
      rowIdList: [],
      activeName: 'chart',
      testSet:[
        {
          tableId:1,
          tableName:"根层级",
          rowId:2,
        }
      ],
      dataForm: {
        key: ''
      },
      dataListSelections: [],
      tableData: [],
      tableCols: [],
      isLeaf: 0,
      isLeafnull: 0,
      projectId: 0,
      id: 0,
      tableId: 0,
      itemId: 0,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
      toSonVisible: false,
      parentLevel:[
        {
          name: "cengji1",
          id: 1
        },
        {
          name: "cengji2",
          id: 2
        }
      ],
      enterState: 0,   //进入状态，0代表直接进入，1代表通过层级进入，2代表搜索进入（显示全层级的），3代表搜索进入（显示特定父层级下）
      //0代表进入到第二层，1代表进入到第三层以及以后，2代表在第二层点击查询后的状态，3代表第三层以及以后点击查询的状态
    }
  },
  components: {
    AddOrUpdate,
    dataExport,
    // chart,
    // graph
  },
  watch:{
    //路由监听
    //因为只有从导航栏进入，会有url的变化，因此
    $route(to, from){
      if(to.fullPath!==from.fullPath)
      {
         this.initWithTableId(this.$route.query.id)
      }
    }
  },

  mounted() {
    this.tableId = window.sessionStorage.getItem("tableId")
    this.rowId =window.sessionStorage.getItem("rowId")
    this.enterState=window.sessionStorage.getItem("enterState")
    console.log('this.enterState:'+this.enterState)
    if(this.enterState==='0')
    {
      this.init();
    }
  },
  methods:{
    handleExport(id,hbaseTableName) {
      //id原本子设备id，这里为rowid，建立websocket连接
      this.handleLog(id)
      let subEquipmentIds;
      subEquipmentIds = id ? [id] : this.dataExportSelections.map(item => {
        return item.id
      })
      console.log(subEquipmentIds)
      this.$confirm(`确定对[id=${subEquipmentIds.join(',')}]进行[${id ? '导出' : '批量导出'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dataList/exportSubEquipment'),
          method: 'post',
          // data: this.$http.adornData(subEquipmentIds, false)
          data: this.$http.adornData(hbaseTableName, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            // this.$message({
            //   message: '操作成功',
            //   type: 'success',
            //   duration: 1500,
            //   onClose: () => {
            //     this.initData()
            //   }
            // })
          } else {
            this.$message.error(data.msg)
            // this.websocket.close();
          }
        })
      }).catch(() => {})
    },
    handleLog(id) {
      var websocket = null;
      var host = `ws://${this.$store.state.websocket.ip}:${this.$store.state.websocket.port}/hbase/log/dataExport`;
      // if (window.location.protocol == 'http:') {
      //   host = 'ws://' + window.location.host + '/websocket';
      // } else {
      //   host = 'wss://' + window.location.host + '/websocket';
      // }

      //判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        websocket = new WebSocket(host);
      } else if ('MozWebSocket' in window) {
        websocket = new MozWebSocket(host);
      } else {
        alert("该浏览器不支持WebSocket！");
      }
      console.log(host)
      this.initWebSocket(id, websocket);

      // if ("WebSocket" in window) {
      //   let url = `ws://localhost/websocket`;
      //   console.log(url);
      //   this.websocket = new WebSocket(url);
      //   this.initWebSocket();
      // }
    },
    handleDownload(id, hbaseTableName) {
      this.$confirm(`确定对[id=${id}]进行下载操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dataList/downloadSubEquipment'),
          method: 'post',
          // data: this.$http.adornData(id, false)
          data: this.$http.adornData(hbaseTableName, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log(data)
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                window.location.href = "http://" + data.downloadUrl
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})

      // let subEquipmentIds;
      // subEquipmentIds = id ? [id] : this.dataExportSelections.map(item => {
      //   return item.id
      // })
      // console.log(subEquipmentIds)
      // this.$confirm(`确定对[id=${subEquipmentIds.join(',')}]进行[${id ? '下载' : '批量下载'}]操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$http({
      //     url: this.$http.adornUrl('/dataList/downloadSubEquipment'),
      //     method: 'post',
      //     data: this.$http.adornData(subEquipmentIds, false)
      //   }).then(({data}) => {
      //     if (data && data.code === 0) {
      //       this.$message({
      //         message: '操作成功',
      //         type: 'success',
      //         duration: 1500,
      //         onClose: () => {
      //           this.initData()
      //         }
      //       })
      //     } else {
      //       this.$message.error(data.msg)
      //     }
      //   })
      // }).catch(() => {})
    },


    //不能保证session存储信息正确时，使用该函数初始化
    initWithTableId(id,index)
    {
      //46
      console.log('index:'+index)
      this.index = index
      window.sessionStorage.setItem("tableId",id)
      this.init()
    },
    //重置表
    resetTable(){
      console.log('this.enterState:'+this.enterState)
      if(this.enterState===0||this.enterState===2){
        this.$http({
          // url: this.$http.adornUrl('/universaltable/list?tableId=' + this.tableId+'&page='+this.pageIndex+'&size='+this.pageSize  ),
          url: this.$http.adornUrl('/universaltable/getRootLevelWithLimit?id=' + this.projectId+'&page='+this.pageIndex+'&size='+this.pageSize ),
          method: 'get',
        }).then(({data})=>{
          this.tableData=data.data;
          this.totalPage=data.count;
          for(let i=0; i<this.tableData.length;i++) {
            let obj = Object.assign({}, this.tableData[i])
            obj.process = this.global.processMap.get(this.tableData[i].id)
            if (obj.process === undefined) {
              obj.msg = null;
            } else {
              if (obj.process !== 0 && obj.process !== 100) {
                obj.msg = "正在导出，请勿刷新页面"
              } else {
                obj.msg = null;
              }
            }
            this.$set(this.tableData, i, obj)
          }

        })
      }else {
        this.$http({
          // url: this.$http.adornUrl('/universaltable/list?tableId=' + this.tableId+'&page='+this.pageIndex+'&size='+this.pageSize  ),
          url: this.$http.adornUrl('/universaltable/getSonLevelWithLimit?tableId='+this.parentTableId+'&id='+this.parentRowId+'&page='+this.pageIndex+'&size='+this.pageSize),
        }).then(({data})=>{
          this.tableData=data.data;
          this.totalPage=data.count;
        })
      }
      for(let i=0; i<this.tableData.length;i++) {
        let obj = Object.assign({}, this.tableData[i])
        obj.process = this.global.processMap.get(this.tableData[i].id)
        if (obj.process === undefined) {
          obj.msg = null;
        } else {
          if (obj.process !== 0 && obj.process !== 100) {
            obj.msg = "正在导出，请勿刷新页面"
          } else {
            obj.msg = null;
          }
        }
        this.$set(this.tableData, i, obj)
      }
    },
    //初始化函数，用于直接进入层级，没有父层级信息
    init(){
      this.enterState=0
      window.sessionStorage.setItem("enterState",this.enterState)
      this.tableId = window.sessionStorage.getItem("tableId")
      console.log('tableid:'+this.tableId)
      this.projectId = window.sessionStorage.getItem("projectId")
      this.getBreadcrumb(this.tableId)
      this.$http({
        url: this.$http.adornUrl('/level/getById?id='+this.tableId),//获得关于tableid的所有信息
        method: 'get',
      }).then(({data}) =>{
        this.parentTableId=data.levelEntity.parentId;
        console.log('this.parentTableId:'+this.parentTableId)
        window.sessionStorage.setItem("parentTableId",this.parentTableId);
        this.$http({
          url: this.$http.adornUrl('/level/isLeaf?id='+this.tableId),
          method: 'get',
        }).then(({data})=>{
          this.isLeaf=data.data;
        }).then(()=>{
          this.$http({
            url: this.$http.adornUrl('/universaltable/getCols?tableId='+this.tableId),
            method: 'get',
          }).then(({data}) => {
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                //最上方列名
                name: data.data[i].remark,
                //cols为key值，并非真实数据
                cols: data.data[i].name,
              })
            }
          }).then(
              () => {
                //面包屑跳转和初始化加载判断
                if(this.index ===0)
                {
                  // this.hbaseTableName=window.sessionStorage.getItem("hbaseTableName")
                  console.log('1111111111111')
                  //点击第一层面包屑
                  this.rowIdList = [];
                  this.$http({
                    // url: this.$http.adornUrl('/universaltable/list?tableId=' + this.tableId+'&page='+this.pageIndex+'&size='+this.pageSize  ),
                    url: this.$http.adornUrl('/universaltable/getRootLevelWithLimit?id=' + this.projectId+'&page='+this.pageIndex+'&size='+this.pageSize ),
                    method: 'get',
                  }).then(({data})=>{
                    this.tableData=data.data;
                    this.totalPage=data.count;
                    for(let i=0; i<this.tableData.length;i++) {
                      let obj = Object.assign({}, this.tableData[i])
                      obj.process = this.global.processMap.get(this.tableData[i].id)
                      if (obj.process === undefined) {
                        obj.msg = null;
                      } else {
                        if (obj.process !== 0 && obj.process !== 100) {
                          obj.msg = "正在导出，请勿刷新页面"
                        } else {
                          obj.msg = null;
                        }
                      }
                      this.$set(this.tableData, i, obj)
                    }
                  }).then(()=>{
                    this.$http({
                      url: this.$http.adornUrl('/universaltable/getRootLeafLevelWithLimit?id=' + this.projectId+'&page='+this.pageIndex+'&size='+this.pageSize ),
                      method: 'get',
                    }).then(({data}) => {
                      //第二层叶子
                      this.tableHeaderTest=[]
                      for(let i=0;i<data.data.length;i++)
                      {
                        // this.tableHeaderTest.push({
                        //   name: data.data[i].remark,
                        //   cols: data.data[i].name,
                        // })
                        let o = {name:data.data[i].name}

                        this.tableHeaderTest.push(o)

                      }
                      console.log('@@@this.tableHeaderTest:'+this.tableHeaderTest)
                    })
                  })
                }else {
                  //每个面包屑的父亲rowid
                  console.log('@@@@@@index:'+this.index)
                  this.parentRowId = this.rowIdList[this.index-1]
                  console.log('@@this.rowIdList1:'+this.rowIdList)
                  //保留包括0不包括index
                  this.rowIdList = this.rowIdList.slice(0,this.index)
                  console.log('@@this.rowIdList2:'+this.rowIdList)
                  this.hbaseTableName = this.hbaseTableNameList[this.index-1]
                  console.log('123hb:'+this.hbaseTableName)
                  this.hbaseTableNameList =this.hbaseTableNameList.slice(0,this.index)
                  this.$http({
                    // url: this.$http.adornUrl('/universaltable/list?tableId=' + this.tableId+'&page='+this.pageIndex+'&size='+this.pageSize  ),
                    url: this.$http.adornUrl('/universaltable/getSonLevelWithLimit?tableId='+this.parentTableId+'&id='+this.parentRowId+'&page='+this.pageIndex+'&size='+this.pageSize),
                  }).then(({data})=>{
                    this.tableData=data.data;
                    this.totalPage=data.count;
                    for(let i=0; i<this.tableData.length;i++) {
                      let obj = Object.assign({}, this.tableData[i])
                      obj.process = this.global.processMap.get(this.tableData[i].id)
                      if (obj.process === undefined) {
                        obj.msg = null;
                      } else {
                        if (obj.process !== 0 && obj.process !== 100) {
                          obj.msg = "正在导出，请勿刷新页面"
                        } else {
                          obj.msg = null;
                        }
                      }
                      this.$set(this.tableData, i, obj)
                    }
                  }).then(()=>{
                    this.$http({
                      url: this.$http.adornUrl('/universaltable/getOtherLeafItemWithLimit?tableId='+this.parentTableId+'&id='+this.parentRowId+'&page='+this.pageIndex+'&size='+this.pageSize),
                      method: 'get',
                    }).then(({data}) => {
                      this.tableHeaderTest=[]
                      for(let i=0;i<data.data.length;i++)
                      {
                        // this.tableHeaderTest.push({
                        //   name: data.data[i].remark,
                        //   cols: data.data[i].name,
                        // })
                        let o = {name:data.data[i].name}

                        this.tableHeaderTest.push(o)

                      }
                      console.log('@@@this.tableHeaderTest:'+this.tableHeaderTest)
                    })
                  })
                }
                // this.$refs.exportVue.getSubEquipOption()

          })
        })
      })
    },

    //初始化函数，用于从父层级进入，携带父层级信息
    initWithParent(tableId,rowId,hbaseTableName,index){
      console.log('index:'+index)

      this.hbaseTableName = hbaseTableName
      this.hbaseTableNameList.push(hbaseTableName)
      console.log('@hbaseTableName:'+this.hbaseTableName)
      this.enterState=1
      window.sessionStorage.setItem("enterState",this.enterState)
      this.parentRowId=rowId
      this.rowIdList.push(this.parentRowId)
      console.log('this.rowIdList:'+this.rowIdList)
      this.parentTableId=tableId
      this.$http({
        url: this.$http.adornUrl('/level/getEnabledSon?id='+this.parentTableId),
        method: 'get',
      }).then(({data})=> {
        this.tableId = data.data.id;
      }).then(()=>{
        this.getBreadcrumb(this.tableId)
        window.sessionStorage.setItem("parentRowId",this.parentRowId);
        window.sessionStorage.setItem("parentTableId",this.parentTableId);
        window.sessionStorage.setItem("tableId",this.tableId);
        this.$http({
          url: this.$http.adornUrl('/level/isLeaf?id='+this.tableId),
          method: 'get',
        }).then(({data})=>{
          this.isLeaf=data.data;
        }).then(()=>{
          this.$http({
            url: this.$http.adornUrl('/universaltable/getCols?tableId='+this.tableId),
            method: 'get',
          }).then(({data}) => {
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                name: data.data[i].remark,
                cols: data.data[i].name,
              })
            }
          }).then(() => {
            //获取第三层非叶子和叶子
            this.$http({
              // url: this.$http.adornUrl('/universaltable/listWithParentRowId?parentTableId='+this.parentTableId+'&parentRowId='+this.parentRowId+
              //     '&tableId=' + this.tableId+'&page='+this.pageIndex+'&size='+this.pageSize  ),
              url: this.$http.adornUrl('/universaltable/getSonLevelWithLimit?tableId='+this.parentTableId+'&id='+this.parentRowId+'&page='+this.pageIndex+'&size='+this.pageSize),
              method: 'get',
            }).then(({data})=>{
              this.tableData=data.data;
              this.totalPage=data.count;
              for(let i=0; i<this.tableData.length;i++) {
                let obj = Object.assign({}, this.tableData[i])
                obj.process = this.global.processMap.get(this.tableData[i].id)
                if (obj.process === undefined) {
                  obj.msg = null;
                } else {
                  if (obj.process !== 0 && obj.process !== 100) {
                    obj.msg = "正在导出，请勿刷新页面"
                  } else {
                    obj.msg = null;
                  }
                }
                this.$set(this.tableData, i, obj)
              }
            }).then(()=>{
              this.$http({
                url: this.$http.adornUrl('/universaltable/getOtherLeafItemWithLimit?tableId='+this.parentTableId+'&id='+this.parentRowId+'&page='+this.pageIndex+'&size='+this.pageSize),
                method: 'get',
              }).then(({data}) => {
                //获取叶子节点名称
                this.tableHeaderTest=[]
                for(let i=0;i<data.data.length;i++)
                {
                  // this.tableHeaderTest.push({
                  //   name: data.data[i].remark,
                  //   cols: data.data[i].name,
                  // })
                  let o = {name:data.data[i].name}

                  this.tableHeaderTest.push(o)

                }
                console.log('@@@this.tableHeaderTest:'+this.tableHeaderTest)
              })
              // this.$refs.exportVue.getSubEquipOption()
            })
          })
        })
      })
    },

    //多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },

    //搜索，同时强制返回第一页
    selectByNameAndInit()
    {
      this.pageIndex=1
      this.selectByName()
    },
    //根据当前页面为直接进入还是父层级进入进行搜索方式的区分
    selectByName(){
      if(this.enterState===0||this.enterState===2) {
          this.selectEnterDirect()
      }
      else {
          this.selectWithParent()
      }
    },

    //直接进入页面的搜索方式
    selectEnterDirect() {
      this.enterState=2
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        // url: this.$http.adornUrl('/universaltable/selectByName'),
        url: this.$http.adornUrl('/universaltable/queryRootNotLeafItemByName'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          // 'limit': this.pageSize,
          'size': this.pageSize,
          'name': this.dataForm.name,
          'tableId': this.tableId,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          for(let i=0; i<this.tableData.length;i++) {
            let obj = Object.assign({}, this.tableData[i])
            obj.process = this.global.processMap.get(this.tableData[i].id)
            if (obj.process === undefined) {
              obj.msg = null;
            } else {
              if (obj.process !== 0 && obj.process !== 100) {
                obj.msg = "正在导出，请勿刷新页面"
              } else {
                obj.msg = null;
              }
            }
            this.$set(this.tableData, i, obj)
          }
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },

    //通过父层级进入页面的搜索方式
    selectWithParent(){
      this.enterState=3
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        // url: this.$http.adornUrl('/universaltable/selectByNameWithParent'),
        url: this.$http.adornUrl('/universaltable/queryOtherNotLeafItemByName'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          // 'limit': this.pageSize,
          'size': this.pageSize,
          'name': this.dataForm.name,
          'tableId': this.tableId,
          'parentRowId': this.parentRowId,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          for(let i=0; i<this.tableData.length;i++) {
            let obj = Object.assign({}, this.tableData[i])
            obj.process = this.global.processMap.get(this.tableData[i].id)
            if (obj.process === undefined) {
              obj.msg = null;
            } else {
              if (obj.process !== 0 && obj.process !== 100) {
                obj.msg = "正在导出，请勿刷新页面"
              } else {
                obj.msg = null;
              }
            }
            this.$set(this.tableData, i, obj)
          }
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },

    //获取导航栏信息（面包屑，层级）
    getBreadcrumb(id) {
      this.$http({
        url: this.$http.adornUrl('/level/getParentList?id='+this.tableId),
        method: 'get',
      }).then(({data})=> {
        this.parentLevel=data.data
      })
    },

    //添加与修改
    addOrUpdateHandle(leaf,id){
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(leaf,id)
      })
    },

    //删除
    deleteHandle (tableId,id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/universaltable/delete?tableId='+ tableId),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.init()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //子层级选择，已弃用，改为通过enabled进行选择
    toSon(tableId,id){
      this.toSonVisible = true
      this.$nextTick(()=>{
        this.$refs.toSon.init(tableId,id)
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else if(this.enterState===1)
      {
        this.initWithParent(this.parentTableId,this.parentRowId)
      }
      else
      {
        this.selectByNameAndInit()
      }
    },
    // 翻页函数
    currentChangeHandle (val) {
      this.pageIndex = val
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else if(this.enterState===1)
      {
        this.initWithParent(this.parentTableId,this.parentRowId)
      }
      else
      {
        this.selectByName()
      }
    },


  }
}
</script>

<style scoped>

</style>