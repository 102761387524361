<template>
  <!-- 查看数据质量信息 -->
  <div>
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">-->
<!--      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>数据管理</el-breadcrumb-item>-->
<!--      &lt;!&ndash;      <el-breadcrumb-item :to="{ path: '/dataManage-dataList/dataList' }">{{dpCrumb}} 系统数据列表</el-breadcrumb-item>&ndash;&gt;-->
<!--      &lt;!&ndash;      <el-breadcrumb-item :to="{ path: '/dataList-checkData?systemId=' + systemId}">{{preCrumb}} 设备数据列表</el-breadcrumb-item>&ndash;&gt;-->
<!--      <el-breadcrumb-item><span @click="back(-2)" style="font-weight:bold">{{dpCrumb}} 系统列表</span></el-breadcrumb-item>-->
<!--      <el-breadcrumb-item><span @click="back(-1)" style="font-weight:bold">{{preCrumb}} 设备列表</span> </el-breadcrumb-item>-->
<!--      &lt;!&ndash;            <el-breadcrumb-item >查看</el-breadcrumb-item>&ndash;&gt;-->
<!--      <el-breadcrumb-item >{{currentEquipmentName}} 附属设备</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->

<!--    <el-form :inline="true">-->
<!--&lt;!&ndash;      <el-form-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <span style="font-size: 10px;">搜索：</span>&ndash;&gt;-->
<!--&lt;!&ndash;        <el-select v-model="currentEquipmentId" placeholder="请选择设备" @change="selectEquipment()" style="width: 400px">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">&ndash;&gt;-->
<!--&lt;!&ndash;          </el-option>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-select>&ndash;&gt;-->
<!--&lt;!&ndash;      </el-form-item>&ndash;&gt;-->
<!--      <el-form-item>-->
<!--        &lt;!&ndash;        <el-button type="primary" @click="handleExport()" :disabled="dataExportSelections.length <= 0" >批量导出</el-button>&ndash;&gt;-->
<!--        &lt;!&ndash;        <el-button type="danger">全部导出</el-button>&ndash;&gt;-->
<!--      </el-form-item>-->
<!--    </el-form>-->
    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        row-key="id"
    >
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          min-width="5%">
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="id"-->
<!--          label="附属设备id"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%"-->
<!--          v-if="false"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          prop="name"
          label="数据项名称"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="measurePointCount"-->
<!--          label="数据项个数"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          prop="createdate"
          label="创建时间"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="handleExport(scope.row.id)"
          >导出</el-button>
          <el-button
              type="text"
              size="small"
              @click="handleDownload(scope.row.id, scope.row.name)"
          >下载</el-button>
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleLog(scope.row.id, scope.row.name)"-->
          <!--          >查看进度</el-button>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="process"
          label="进度"
          header-align="center"
          align="center"
          min-width="10%">
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.process"></el-progress>
          <span v-if="scope.row.msg">{{scope.row.msg}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="remark"-->
<!--          label="备注"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="20%">-->
<!--      </el-table-column>-->
    </el-table>
    <div style="display: flex;justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import Vue from "vue";

export default {

  name: "data-export-subEquipment",
  data(){
    return{
      moreVisible:false,
      systemId: undefined,
      dataListLoading: false,
      tableData:[],
      options: [],
      currentEquipmentId: '',
      currentEquipmentName: '',
      page:1,  //当前第几页
      size:10, //当前每页个数
      total:0, //全部数据行数
      preCrumb: '',
      dpCrumb: '',
      crumb: '',
      dataExportSelections: [],
      websocket: ''
    }
  },
  components: {
  },
  mounted() {
    this.systemId = this.$route.query.systemId
    this.preCrumb = this.$route.query.pCrumb
    this.dpCrumb = this.$route.query.dpCrumb
    this.crumb = this.$route.query.crumb
    this.initData()
  },
  methods: {
    currentChange(currentPage){
      this.page=currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    initData() {
      this.dataListLoading = true;
      this.getRequest("/dataList/getEquipmentBySubsystemId?subsystemId=" + this.$route.query.subSystemId).then((resp) => {
        if (resp.data) {
          this.options = resp.data
          this.currentEquipmentId = this.options[0].id
          this.currentEquipmentName = this.options[0].name
        }
      }).then(() => {
        this.getRequest(
            "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
            this.$route.query.equipmentId + "&page=" + this.page + "&size=" + this.size
        ).then((resp) => {
          if (resp.data) {

            let temp = {
              "id": 8,
              "name": "电机",
              "createdate": "2021-10-04",
              "enabled": true,
              "delete": null,
              "uid": null,
              "hbasetablename": "subEquip_dianji_8",
              "remark": "电机",
              "measurePointCount": 11
            }




            this.tableData = resp.data.data;
            console.log(this.global.processMap.keys())
            console.log(this.global.processMap.get(33))
            // console.log(this.$getProcess(33))
            for(let i=0; i<this.tableData.length;i++) {
              let obj = Object.assign({}, this.tableData[i])
              obj.process = this.global.processMap.get(this.tableData[i].id)
              if (obj.process === undefined) {
                obj.msg = null;
              } else {
                if (obj.process !== 0 && obj.process !== 100) {
                  obj.msg = "正在导出，请勿刷新页面"
                } else {
                  obj.msg = null;
                }
              }
              this.$set(this.tableData, i, obj)
            }
            // this.tableData.forEach(item => {
            //   if (item.id in this.global.processMap.keys()) {
            //     console.log(this.global.processMap.get(item.id).process)
            //     this.$set(item.process, this.global.processMap.get(item.id).process)
            //     // item.process = this.global.processMap.get(item.id).process
            //   } else
            //     item.process = 0
            // })
            console.log(this.tableData)
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      })
    },
    selectEquipment() {
      this.dataListLoading = true
      let obj = {}
      obj = this.options.find((item)=>{
        return item.id === this.currentEquipmentId; // 筛选出匹配数据
      });
      this.currentEquipmentName = obj.name
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
          this.currentEquipmentId + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    back(count) {
      history.go(count)
    },
    selectionChangeHandle (val) {
      this.dataExportSelections = val
    },
    handleExport(id) {
      //id为子设备id
      this.handleLog(id)
      let subEquipmentIds;
      subEquipmentIds = id ? [id] : this.dataExportSelections.map(item => {
        return item.id
      })
      console.log(subEquipmentIds)
      this.$confirm(`确定对[id=${subEquipmentIds.join(',')}]进行[${id ? '导出' : '批量导出'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dataList/exportSubEquipment'),
          method: 'post',
          data: this.$http.adornData(subEquipmentIds, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            // this.$message({
            //   message: '操作成功',
            //   type: 'success',
            //   duration: 1500,
            //   onClose: () => {
            //     this.initData()
            //   }
            // })
          } else {
            this.$message.error(data.msg)
            // this.websocket.close();
          }
        })
      }).catch(() => {})
    },
    handleLog(id) {
      var websocket = null;
      var host = `ws://${this.$store.state.websocket.ip}:${this.$store.state.websocket.port}/hbase/log/dataExport`;
      // if (window.location.protocol == 'http:') {
      //   host = 'ws://' + window.location.host + '/websocket';
      // } else {
      //   host = 'wss://' + window.location.host + '/websocket';
      // }

      //判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        websocket = new WebSocket(host);
      } else if ('MozWebSocket' in window) {
        websocket = new MozWebSocket(host);
      } else {
        alert("该浏览器不支持WebSocket！");
      }
      console.log(host)
      this.initWebSocket(id, websocket);

      // if ("WebSocket" in window) {
      //   let url = `ws://localhost/websocket`;
      //   console.log(url);
      //   this.websocket = new WebSocket(url);
      //   this.initWebSocket();
      // }
    },
    initWebSocket(id, websocket) {
      // 连接错误
      websocket.onerror = () => {
        console.log(
            "WebSocket连接发生错误   状态码：" + websocket.readyState
        );
      };
      // 连接成功
      websocket.onopen = () => {
        console.log(
            "WebSocket连接成功    状态码：" + websocket.readyState
        );
      };
      // 收到消息的回调
      websocket.onmessage = (event) => {
        // this.consumeCount = this.consumeCount + 1;
        // if (this.filter && event.data.indexOf(this.keyword) == -1) {
        //   return
        // }
        console.log(event.data)
        if (event.data.match("开始导出")) {
          console.log("10")
          for(let i=0; i<this.tableData.length;i++) {
            if(this.tableData[i].id == id) {
              this.tableData[i].msg = "正在导出，请勿刷新页面"
              let obj = Object.assign({}, this.tableData[i])
              obj.process = 10
              this.$set(this.tableData, i, obj)
              this.$setProcess(id, 10)
              console.log(this.global.processList)
            }
          }
        }
        // if (event.data.match("成功导出")) {
        //   console.log("50")
        //   for(let i=0; i<this.tableData.length;i++) {
        //     if(this.tableData[i].id == id) {
        //       let obj = Object.assign({}, this.tableData[i])
        //       obj.process = 50
        //       this.$set(this.tableData, i, obj)
        //       this.$setProcess(id, 50)
        //     }
        //   }
        // }
        if (event.data.match("成功导出")) {
          console.log("100")
          for(let i=0; i<this.tableData.length;i++) {
            if(this.tableData[i].id == id) {
              this.tableData[i].msg = null
              let obj = Object.assign({}, this.tableData[i])
              obj.process = 100
              this.$set(this.tableData, i, obj)
              this.$setProcess(id, 100)
              this.$message({
                type: "success",
                message: this.tableData[i].name + "导出成功",
                duration: 1500,
              });
            }
          }
          websocket.close();
        }
        if (event.data.match("请重试或联系管理员") || event.data.match("导出失败")) {
          console.log("失败")
          for(let i=0; i<this.tableData.length;i++) {
            if(this.tableData[i].id == id) {
              this.tableData[i].msg = null
              let obj = Object.assign({}, this.tableData[i])
              obj.process = 0
              this.$set(this.tableData, i, obj)
              this.$setProcess(id, 0)
              this.$message({
                type: "error",
                message: this.tableData[i].name + "导出失败",
                duration: 1500,
              });
            }
          }
          websocket.close();
        }
        // this.message.push(event.data);
        // if (this.autoScrollToBottom) this.scroll();
      };
      // 连接关闭的回调
      websocket.onclose = () => {
        console.log(
            "WebSocket连接关闭    状态码：" + this.websocket.readyState
        );
      };
      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        websocket.close();
      };
    },
    handleDownload(id, name) {
      this.$confirm(`确定对[id=${id}]进行下载操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dataList/downloadSubEquipment'),
          method: 'post',
          data: this.$http.adornData(id, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log(data)
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                window.location.href = "http://" + data.downloadUrl
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})

      // let subEquipmentIds;
      // subEquipmentIds = id ? [id] : this.dataExportSelections.map(item => {
      //   return item.id
      // })
      // console.log(subEquipmentIds)
      // this.$confirm(`确定对[id=${subEquipmentIds.join(',')}]进行[${id ? '下载' : '批量下载'}]操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$http({
      //     url: this.$http.adornUrl('/dataList/downloadSubEquipment'),
      //     method: 'post',
      //     data: this.$http.adornData(subEquipmentIds, false)
      //   }).then(({data}) => {
      //     if (data && data.code === 0) {
      //       this.$message({
      //         message: '操作成功',
      //         type: 'success',
      //         duration: 1500,
      //         onClose: () => {
      //           this.initData()
      //         }
      //       })
      //     } else {
      //       this.$message.error(data.msg)
      //     }
      //   })
      // }).catch(() => {})
    }
  },

}
</script>


<style scoped>

</style>
